<template>
  <!--  <v-card class="mx-auto rounded-xl" color="neutral" max-width="1000" tile>-->
  <!--    <v-img height="200" src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"></v-img>-->
  <!--    <v-row style="margin:2.5%; position:absolute; top: 130px;">-->
  <!--      <v-list-item>-->
  <!--        <v-list-item-avatar size="100">-->
  <!--          <img-->
  <!--            src="https://www.w3schools.com/howto/img_avatar.png"-->
  <!--            alt="John"-->
  <!--          >-->
  <!--        </v-list-item-avatar>-->
  <!--        <v-list-item-content>-->
  <!--          <v-list-item-title class="title" style="margin-top:20px;">Your Company</v-list-item-title>-->
  <!--          <v-list-item-subtitle>Software Engineer | dokanee</v-list-item-subtitle>-->
  <!--        </v-list-item-content>-->
  <!--      </v-list-item>-->
  <!--    </v-row>-->
  <!--  </v-card>-->
  <v-card
    class="mx-auto rounded-xl"
    color="neutral"
  >
    <div
      class="hero-background"
    />
    <v-row class="mb-4">
      <v-col>
        <v-card-title
          class="pb-0"
          v-text="adminForCompanies[0].companyName"
        />
        <v-card-text>
          <div class="text-caption mb-4">
            <v-icon small>
              mdi-account-multiple
            </v-icon>
            {{ companyMembersLength }} {{ $t('Pages.Company.members') }}
          </div>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text>
          <CompanyInviteLink id="members" />
        </v-card-text>
      </v-col>
    </v-row>
    <v-avatar
      v-if="isImplemented"
      class="profile rounded-xl"
      color="grey"
      size="150"
      style="position: absolute; top: 75px; left: 1rem;"
      tile
    >
      <v-img src="https://via.placeholder.com/150" />
    </v-avatar>
  </v-card>
</template>

<script>
import { mdiCardTextOutline } from '@mdi/js'
import CompanyInviteLink from '@/pages/company/CompanyInviteLink.vue'
import { mapState } from 'vuex'

export default {
  name: 'CompanyTopCard',
  components: { CompanyInviteLink },
  data: () => ({
    svgPath: mdiCardTextOutline,
    isImplemented: false
  }),
  computed: {
    ...mapState({
      adminForCompanies: state => state.adminForCompanies,
      companyMembersLength: state => state.companyMembers.length
    })
  }
}
</script>

<style scoped>
.hero-background {
  height: 50px;
  background-repeat: repeat;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'%3E%3Cg fill='%23042c58' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");
}

.link-container {
  position: absolute;
  right: 1rem;
  top: 170px;
}

</style>
