<template>
  <div class="company-invite-container">
    <v-text-field
      :id="'invite-link-' + id"
      :value="companyInviteLink"
      outlined
      :label="$t('Pages.Company.invite_link')"
      type="text"
      color="accent"
      rounded
      dense
      hide-details
      @focus="copyInviteLink(companyInviteLink)"
    />
    <v-btn
      class="ml-2"
      outlined
      rounded
      text
      @click="copyInviteLink(companyInviteLink)"
      v-text="$t('Common.copy')"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'CompanyInviteLink',
  props: {
    id: {
      type: String,
      default: 'base'
    }
  },
  computed: {
    ...mapState({
      adminForCompanies: 'adminForCompanies',
      companyInviteLink: state => 'gigport.se/sv/join/' + state.companyInviteLink
    })
  },
  methods: {
    copyInviteLink (string) {
      const textfield = document.getElementById('invite-link-' + this.id)
      textfield.focus()
      textfield.select()
      navigator.clipboard.writeText(string)
    }
  }
}
</script>

<style scoped>
.company-invite-container {
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
