<template>
  <section
    id="company"
  >
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="4"
          md="3"
        >
          <v-list
            color="neutral"
            class="py-2 rounded-xl"
            rounded
          >
            <template v-for="setting in companyListItems">
              <v-list-item
                :id="setting.page"
                :key="setting.title"
                :to="{ name: setting.page }"
                exact
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t(setting.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="9"
        >
          <CompanyTopCard class="mb-4" />
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import CompanyTopCard from '@/pages/company/CompanyTopCard.vue'
import { mapState } from 'vuex'
import { GET_COMPANY_DETAILS, GET_COMPANY_INVITE_LINK, GET_COMPANY_MEMBERS } from '@/store/actions.type'

export default {
  name: 'Company',
  components: {
    CompanyTopCard
  },
  data () {
    return {
      companyListItems: [
        {
          title: 'Pages.Company.members',
          page: 'company-members'
        },
        {
          title: 'Pages.Company.details',
          page: 'company-details'
        }
        // {
        //   title: 'Pages.Company.projects',
        //   page: 'company-projects'
        // }
      ]
    }
  },
  computed: {
    ...mapState([
      'adminForCompanies'
    ])
  },
  created () {
    this.getCompanyDetails()
    this.getCompanyMembers()
    this.getInviteLink()
  },
  methods: {
    getCompanyDetails () {
      this.$store.dispatch(GET_COMPANY_DETAILS, this.$store.state.adminForCompanies[0].companyId).then(() => {
      }).catch((error) => {
        this.$root.$emit('errorMessage', error)
      })
    },
    getInviteLink () {
      this.$store.dispatch(GET_COMPANY_INVITE_LINK, this.$store.state.adminForCompanies[0].companyId).then(() => {
      }).catch((error) => {
        this.$root.$emit('errorMessage', error)
      })
    },
    getCompanyMembers () {
      if (this.$store.state.adminForCompanies.length) {
        this.$store.dispatch(GET_COMPANY_MEMBERS, this.$store.state.adminForCompanies[0].companyId).then(() => {
        }).catch((error) => {
          this.$root.$emit('errorMessage', error)
        })
      } else {
        console.warn('Not admin for any companies')
      }
    }
  }
}
</script>

<style scoped>

</style>
